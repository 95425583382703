import {Component, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {Base64UploadAdapterPlugin, ChangesService} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { TranslateService, FilterPipe, NotificationService } from 'swx.front-end-lib';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    templateUrl: 'StationDetail.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`.ck-editor__editable_inline {
                min-height: 200px;
            }`,],
})
export class StationDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    @ViewChild('alertsForm', { static: true }) alertsForm;
    tab;
    stationTypes = this.api.StationType.query();
    gen2StationConnectionTypes = {
        TCPClient: "TCP client",
        MQTT: "MQTT",
    };
    gen3StationConnectionTypes = {
        TCPClient: "TCP client",
        TCPXMLPolling: "TCP XML polling",
        HTTPChannelsPolling: "HTTP channels.shm polling",
        MQTT: "MQTT",
    };
    genVStationConnectionTypes = {
        TCPXMLPolling: "TCP XML polling",
        MQTT: "MQTT",
    };
    serialOverTCPStationConnectionTypes = {
        TCPClient: "TCP serial client",
        SSHClient: "SSH",
    };
    sureLogger2StationConnectionTypes = {
        MQTT: "MQTT",
    };
    stationLoggerTypes = this.api.StationLoggerType.query();
    campbellPWS100LaserControlModes = this.api.CampbellPWS100LaserControlMode.query();
    airports = this.api.Airport.query();
    stationProblemConfigurationProfiles = this.api.StationProblemConfigurationProfile.query();
    stationAlertTemplates = this.api.StationAlertTemplate.query();
    clients = this.api.Client.query();
    clientContacts = this.api.ClientContact.query();
    mqttBrokers = this.api.MQTTBroker.query();
    alert: any = {};
    from: any;
    alertLevel1: any;
    alertLevel2: any;
    stationAlertTemplateId: null;
    Editor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.Station.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.StationConnectionConfigurations) {
                    this.item.StationConnectionConfigurations.forEach(related => {
                        delete related.StationId;
                    });
                }

                if (this.item.StationSerialSensorConfigurations) {
                    this.item.StationSerialSensorConfigurations.forEach(related => {
                        delete related.StationId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.Station.create({
                Active: true,
            });
        } else {
            this.item = this.api.Station.get({ id: id });
            this.item.$promise.then(() => {
                this.alert.MessageSent = this.item.MessageSent;
                if (!this.item.UnderMaintenance) this.item.UnderMaintenanceNotes = '';
            });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.item.StationSerialSensorConfigurations = this.item.StationSerialSensorConfigurations || [];
                this.item.StationConnectionConfigurations = this.item.StationConnectionConfigurations || [];
                
                this.item.StationConnectionConfigurations = this.item.StationConnectionConfigurations.sort((r1, r2) => {
                    return r1.SerialPortNumber > r2.SerialPortNumber
                        ? 1
                        : (r1.SerialPortNumber < r2.SerialPortNumber)
                            ? -1
                            : (r1.Order > r2.Order
                                ? 1
                                : (r1.Order < r2.Order
                                    ? -1
                                    : 0));
                });

                this.item.StationSerialSensorConfigurations = this.item.StationSerialSensorConfigurations.sort((r1, r2) => {
                    return r1.SerialPortNumber > r2.SerialPortNumber
                        ? 1
                        : (r1.SerialPortNumber < r2.SerialPortNumber)
                            ? -1
                            : (r1.Order > r2.Order
                                ? 1
                                : (r1.Order < r2.Order
                                    ? -1
                                    : 0));
                });
            });
        }

        var config = this.api.Config.queryObject();
        config.$promise.then(() => {
            this.from = config.DefaultSenderEmailAddress;
        });
    }
    
    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['Station'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }
    
    addStationConnectionConfiguration() {
        this.item.StationConnectionConfigurations = this.item.StationConnectionConfigurations || [];

        var maxOrder = Math.max(0, Math.max.apply(null, this.item.StationConnectionConfigurations
            .map(h => h.Order)));

        this.item.StationConnectionConfigurations.push({
            Order: Math.max(1, maxOrder + 1),
            Active: true,
        });
        this.ngForm.form.markAsDirty();
    }

    removeStationConnectionConfiguration(stationConnectionConfiguration) {
        this.item.StationConnectionConfigurations.splice(this.item.StationConnectionConfigurations.indexOf(stationConnectionConfiguration), 1);
        this.ngForm.form.markAsDirty();
    }

    addStationSerialSensorConfiguration() {
        this.item.StationSerialSensorConfigurations = this.item.StationSerialSensorConfigurations || [];

        var maxOrder = Math.max(0, Math.max.apply(null, this.item.StationSerialSensorConfigurations
            .map(h => h.Order)));

        this.item.StationSerialSensorConfigurations.push({
            Order: Math.max(1, maxOrder + 1),
            Active: true,
        });
        this.ngForm.form.markAsDirty();
    }

    removeStationSerialSensorConfiguration(stationSerialSensorConfiguration) {
        this.item.StationSerialSensorConfigurations.splice(this.item.StationSerialSensorConfigurations.indexOf(stationSerialSensorConfiguration), 1);
        this.ngForm.form.markAsDirty();
    }

    stationAlertTemplateIdChanged(stationAlertTemplateId) {
        if (stationAlertTemplateId) {
            this.alertLevel1 = false;
            this.alertLevel2 = false;
            this.api.StationAlertTemplate.get({ id: stationAlertTemplateId }).$promise
                .then(stationAlertTemplate => {
                    var airportCode = this.airports.find(a => a.Id === this.item.AirportId).ICAOCode;
                    this.alert = {
                        SendToCustomerRepresentatives: stationAlertTemplate.SendToCustomerRepresentatives,
                        Cc: stationAlertTemplate.Cc,
                        Bcc: stationAlertTemplate.Bcc,
                        Subject: stationAlertTemplate.Subject.split('{AirportCode}').join(airportCode),
                        Body: stationAlertTemplate.Body.split('{AirportCode}').join(airportCode)
                    };

                    this.clientContacts.$promise
                        .then(() => {
                            this.clientContacts.forEach(clientContact => {
                                if (clientContact.Email) {
                                    var alertLevel = this.getAlertLevel(clientContact);
                                    clientContact.Selected =
                                        (alertLevel === 1 && stationAlertTemplate.Level1)
                                        || (alertLevel === 2 && stationAlertTemplate.Level2);
                                }
                            });
                        });
                });
        }
    }

    getClientContactsByLevel(level) {
        return this.clientContacts.filter(clientContact => {
            var alertLevel = this.getAlertLevel(clientContact);
            return clientContact.Email && alertLevel === level;
        });
    }

    alertLevel1Changed(newLvl1) {
        this.getClientContactsByLevel(1).forEach(clientContact => {
            clientContact.Selected = newLvl1;
        });
    }

    alertLevel2Changed(newLvl2) {
        this.getClientContactsByLevel(2).forEach(clientContact => {
            clientContact.Selected = newLvl2;
        });
    }

    getAlertLevel(clientContact) {
        var warningTypes = clientContact.ClientContactAirports
            .filter(cca => cca.AirportId === this.item.AirportId)
            .map(cca => cca.WarningType);

        if (warningTypes.some(wt => wt === 'StationAlertLevel2')) {
            return 2;
        } else if (warningTypes.some(wt => wt === 'StationAlertLevel1')) {
            return 1;
        }

        return null;
    };

    contactsWithAlertLevel() {
        return this.clientContacts.filter(c => this.getAlertLevel(c) != null);
    };

    sendAlert() {
        this.alert.ClientContactId = this.clientContacts.filter(c => c.Selected).map(c => c.Id);
        this.alert.MessageSent = this.item.MessageSent;
        this.api.StationAlertEmail.post({
            StationId: this.item.Id,
            ...this.alert
        }).then(() => {
            this.notificationService.show(this.translateService.translate('The alerts have been sent.'));
            this.alert = {};
            this.stationAlertTemplateId = null;
        });
    };
}
