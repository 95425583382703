import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import {FileDownloadService} from "swx.front-end-lib";

@Component({
    templateUrl: 'MetarWeatherMappingProfileDetail.component.html'
})
export class MetarWeatherMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    tab;
    metarWeatherCodeMappingProfiles = this.api.MetarWeatherCodeMappingProfile.query();
    metarWeatherTypeMappingProfiles = this.api.MetarWeatherTypeMappingProfile.query();
    metarWeatherCodeRemappingProfiles = this.api.MetarWeatherCodeRemappingProfile.query();
    //regulatoryAgencies = this.api.RegulatoryAgency.query();
    regulatoryAgencies = {
        FAA20222023: 'FAA',
        FAA20222023DayAsNight: 'FAA (always daytime)',
        TC20222023: 'TC',
    };
    metarWeatherCodeMappingProfile: any = null;
    metarWeatherTypeMappingProfile: any = null;
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private fileDownload: FileDownloadService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MetarWeatherMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.MetarWeatherMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.MetarWeatherMappingProfile.get({ id: id });
        }
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MetarWeatherMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    exportProfile() {
        var query = {
            Id: this.item.Id,
            MetarCodeMappingColumnDefs: [{
                children: [
                    { colId: "Id", field: "MetarWeatherCodeMapping.Id", headerName: "#" },
                    { colId: "ProfileName", field: "MetarWeatherCodeMappingProfileName", headerName: "Profile" },
                    { colId: "WeatherCode", field: "MetarWeatherCodeMapping.WeatherCode", headerName: "Weather code" },
                    { colId: "WeatherTypeId", field: "MetarWeatherCodeMapping.MetarWeatherType.Name", headerName: "Weather type" },
                ]
            }],
            MetarTypeMappingColumnDefs: [{
                children: [
                    { colId: "Id", field: "MetarWeatherTypeMapping.Id", headerName: "#" },
                    { colId: "ProfileName", field: "MetarWeatherTypeMappingProfileName", headerName: "Profile" },
                    { colId: "WeatherTypeId1", field: "MetarWeatherTypeMapping.MetarWeatherType1", headerName: "Weather type 1" },
                    { colId: "WeatherTypeId2", field: "MetarWeatherTypeMapping.MetarWeatherType2", headerName: "Weather type 2" },
                    { colId: "WeatherTypeId3", field: "MetarWeatherTypeMapping.MetarWeatherType3", headerName: "Weather type 3" },
                    { colId: "MetarMixedPrecipitationProfileId", field: "MetarWeatherTypeMapping.MetarMixedPrecipitationProfile.Name", headerName: "Mixed precip profile" },
                    { colId: "WeatherTypeIdResult", field: "MetarWeatherTypeMapping.MetarWeatherTypeResult", headerName: "Final type" },
                ]
            }],
        };

        this.api.MetarWeatherMappingProfileExport
            .exportPost(query)
            .then(response => {
                this.fileDownload.download(response.body, (header) => response.headers.get(header));
            });
    };

    addMetarWeatherCodeMappingProfile() {
        this.item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles = this.item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles || [];

        var maxOrder = Math.max(0, Math.max.apply(null, this.item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles
            .map(h => h.Order)));

        this.item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles.push({
            Order: Math.max(1, maxOrder + 1),
            MetarWeatherCodeMappingProfileId: this.metarWeatherCodeMappingProfile.Id,
        });

        this.metarWeatherCodeMappingProfile = null;
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeMetarWeatherMappingProfileMetarWeatherCodeMappingProfile(metarWeatherMappingProfileMetarWeatherCodeMappingProfile) {
        this.item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles.splice(this.item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles.indexOf(metarWeatherMappingProfileMetarWeatherCodeMappingProfile), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();    };

    addMetarWeatherTypeMappingProfile() {
        this.item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles = this.item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles || [];

        var maxOrder = Math.max(0, Math.max.apply(null, this.item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles
            .map(h => h.Order)));

        this.item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles.push({
            Order: Math.max(1, maxOrder + 1),
            MetarWeatherTypeMappingProfileId: this.metarWeatherTypeMappingProfile.Id,
        });

        this.metarWeatherTypeMappingProfile = null;
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeMetarWeatherMappingProfileMetarWeatherTypeMappingProfile(metarWeatherMappingProfileMetarWeatherTypeMappingProfile) {
        this.item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles.splice(this.item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles.indexOf(metarWeatherMappingProfileMetarWeatherTypeMappingProfile), 1);
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();    };

    metarWeatherCodeMappingProfileNotEnabled(metarWeatherCodeMappingProfiles, metarWeatherMappingProfileMetarWeatherCodeMappingProfiles) {
        if (metarWeatherMappingProfileMetarWeatherCodeMappingProfiles == null) return metarWeatherCodeMappingProfiles;
        return metarWeatherCodeMappingProfiles.filter(p => !metarWeatherMappingProfileMetarWeatherCodeMappingProfiles.some(mp => mp.MetarWeatherCodeMappingProfileId === p.Id));
    };

    metarWeatherTypeMappingProfileNotEnabled(metarWeatherTypeMappingProfiles, metarWeatherMappingProfileMetarWeatherTypeMappingProfiles) {
        if (metarWeatherMappingProfileMetarWeatherTypeMappingProfiles == null) return metarWeatherTypeMappingProfiles;
        return metarWeatherTypeMappingProfiles.filter(p => !metarWeatherMappingProfileMetarWeatherTypeMappingProfiles.some(mp => mp.MetarWeatherTypeMappingProfileId === p.Id));
    };
}
